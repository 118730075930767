import classNames from 'classnames';
import React, { useEffect, Ref, PropsWithChildren } from 'react';

import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';

type Props = PropsWithChildren<{
  offset?: number;
  className: string;
  id?: string;
  rollerRef?: Ref<HTMLDivElement>;
}>;

const Roller = ({ className, offset = 20, id, rollerRef, children }: Props) => {
  useEffect(() => {
    eventEmitter.emit(EventsTypes.ROLLER_REVALIDATE);
  }, [children]);

  const classes = classNames('roller-holder js-roller', className);

  return (
    <div id={id} className={classes} data-offset={offset || 20} ref={rollerRef}>
      <div className="roller-overflow">
        <div className="roller-slider">{children}</div>
      </div>
      <div className="roller-btn-holder">
        <button
          type="button"
          className="roller-btn btn-left icon icon-arrow-left disabled"
        />
        <button
          type="button"
          className="roller-btn btn-right icon icon-arrow-right"
        />
      </div>
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, Props>((props, ref) => (
  <Roller {...props} rollerRef={ref} />
));
