import React from 'react';
import { createRoot } from 'react-dom/client';

import Overlay from 'common/module/ui/Overlay';
import roller from 'common/module/ui/roller';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import * as events from 'common/tools/dom/events';
import readAttribute, {
  readNumberAttribute
} from 'common/tools/dom/readAttribute';

import Slideshow from 'website/components/ui/Slideshow';

const showDiaporama = () => {
  const triggerSlideshow = document.getElementById('slideshow-launcher');
  if (triggerSlideshow) {
    const componentAnchor = document.createElement('div');
    const slideshowData = readAttribute(triggerSlideshow, 'data-slides');
    const entityId = readAttribute(triggerSlideshow, 'data-entity-id');

    if (!componentAnchor || !slideshowData) {
      return false;
    }

    events.on(triggerSlideshow, 'click', () => {
      Overlay.init(componentAnchor, 'diaporama');
      const root = createRoot(componentAnchor);
      root.render(
        <Slideshow obj={slideshowData} fullscreen embed entityId={entityId} />
      );
      roller();
    });
  } else {
    // PAGE CASE
    const componentAnchor = document.getElementById('slideshow-anchor');

    if (!componentAnchor) {
      return false;
    }

    const slideshowData = readAttribute(componentAnchor, 'data-slides');
    const entityId = readAttribute(componentAnchor, 'data-entity-id');
    const fullscreenAds = readNumberAttribute(
      componentAnchor,
      'data-fullscreen-ads',
      0
    );

    const fullscreen = window.location.hash === '#fullscreen';

    if (!componentAnchor || !slideshowData) {
      return false;
    }

    const root = createRoot(componentAnchor);
    root.render(
      <Slideshow
        obj={slideshowData}
        fullscreen={fullscreen}
        embed={false}
        entityId={entityId}
        fullscreenAds={+fullscreenAds === 1}
      />
    );
  }
};
export default () => {
  if (window.adsPositionsReady) {
    showDiaporama();
    return;
  }
  eventEmitter.on(EventsTypes.ADS_POSITIONS_READY, showDiaporama);
};
