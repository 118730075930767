/* eslint-disable react/no-danger */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';

import AdPosition from 'common/components/AdPosition';
import FacebookShareButton from 'common/components/socialNetworks/FacebookShareButton';
import TwitterShareButton from 'common/components/socialNetworks/TwitterShareButton';
import {
  AD_ATF,
  AD_SLIDESHOW_ATF,
  AD_SLIDESHOW_NATIVE_BANNER
} from 'common/constants/Ads';
import { MQ_SMALL } from 'common/constants/MediaQueries';
import eventEmitter, { EventsTypes } from 'common/services/events/eventEmitter';
import { addClass, removeClass } from 'common/tools/dom/classes';
import * as events from 'common/tools/dom/events';
import { getState } from 'common/tools/dom/mq-state';
import swipeOn, {
  SWIPE_BOTTOM,
  SWIPE_LEFT,
  SWIPE_RIGHT,
  SWIPE_TOP
} from 'common/tools/dom/swipe';
import { getImageUrl } from 'common/tools/network/url';
import { desobfuscateUrl } from 'common/tools/seo/desobfuscation';
import trans from 'common/tools/translations/trans';
import { agofHit } from 'common/tracking/agof';

import Roller from 'website/components/ui/Roller';
import {
  KEY_DOWN,
  KEY_ESC,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_UP
} from 'website/constants/Keyboard';
import { gemiusHit } from 'website/tracking/gemius';

/* seo parsing mode constants */
const PARSINGMODE_NONE = 'none';
const PARSINGMODE_TITLE = 'title';
const PARSINGMODE_DESC = 'description';
const PARSINGMODE_TITLEORDESC = 'titleordesc';
const PARSINGMODE_TITLEANDDESC = 'titleanddesc';

const START_PATH = window.location.pathname;

/*
 * - getStartIndex => get the first item of the slideshow (slideshow can be open in #4 page)
 * - getAdsPosition => get the ad position to show (ATF or SLIDESHOW)
 * - handleKeydown => use to navigate the slideshow with keyboard
 * - handleSwipe => use to navigate the slideshow by swiping (mobile)
 * - handleChange => core function to handle the page (slide) change
 * - handleDoubleClick => use to open fullscreen by double-clicking on img (or double tapping)
 * - handleSrcSizeUrl => set the right size image regarding the "device / resolution"
 * - handleRoute => return the good route parameters (seo matters)
 */

class Slideshow extends Component {
  static propTypes = {
    obj: PropTypes.array.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    embed: PropTypes.bool.isRequired,
    entityId: PropTypes.any,
    fullscreenAds: PropTypes.bool
  };

  static defaultProps = {
    entityId: 0,
    fullscreenAds: true
  };

  constructor(props, context) {
    super(props, context);

    this.refreshFullScreenAd = false;
    this.doubleClickTimer = null;
    this.itemIteration = 0;

    this.state = {
      currentIndex: this.getStartIndex(),
      showInfo: false,
      fullscreen: this.props.fullscreen,
      showImg: true
    };

    events.on(window, 'keydown', this.handleKeydown);
  }

  componentDidMount() {
    this.root = document.getElementById('slideshow');
    this.roller = document.getElementById('slideshow-roller');

    window.onpopstate = event => {
      let index;
      if (event.state && event.state.page) {
        index = event.state.page - 1;

        if (index === -1) {
          index = 0;
        }
      } else {
        index = this.getStartIndex();
      }

      this.handleChange(index, false);
    };

    swipeOn(this.root, this.handleSwipe);

    if (this.props.fullscreen) {
      this.startFullscreen();
    }
  }

  /**
   * @param _prevProps
   * @param prevState
   */
  componentDidUpdate(_prevProps, prevState) {
    this.refreshFullScreenAd =
      this.state.fullscreen && this.state.fullscreen === prevState.fullscreen;

    if (this.state.fullscreen !== prevState.fullscreen) {
      // if fullscreen state change: revalidate roller "instance" and get that instance.
      eventEmitter.emit(EventsTypes.ROLLER_REVALIDATE);
      this.roller = document.getElementById('slideshow-roller');
    }

    if (this.state.fullscreen === prevState.fullscreen) {
      // trigger current roller event change to check if we have to push in view the current item.
      eventEmitter.emit(EventsTypes.ROLLER_GETCURRENT_ITEM, this.roller);
    }
  }

  getStartIndex() {
    // get param if any, and retrieve the page number form it.
    // set the good current slide on Slideshow render.
    const param = window.location.search.split('?')[1] || window.location.hash;
    const paramMatch = param.match(/(page=+[0-9]+)/);

    if (!param || !paramMatch) {
      return 0;
    }

    const pageNumber = paramMatch[0].match(/([0-9]+)/);
    let index = parseInt(pageNumber[0], 10) - 1; // - 1 is necessary to get the index and not page number.

    if (index >= this.props.obj.length) {
      index = this.props.obj.length - 1;
    } else if (index <= 0) {
      index = 0;
    }

    return index;
  }

  getAdsPosition() {
    let adsPosition = AD_ATF;

    if (this.refreshFullScreenAd) {
      adsPosition = AD_SLIDESHOW_ATF;
    }

    return { advertisementTargets: [adsPosition, AD_SLIDESHOW_NATIVE_BANNER] };
  }

  handleKeydown = event => {
    switch (
      event.keyCode // .key & .code don't work on Safari. (safari bitch)
    ) {
      case KEY_LEFT:
        this.handleChange(this.state.currentIndex - 1);
        break;
      case KEY_RIGHT:
        this.handleChange(this.state.currentIndex + 1);
        break;
      case KEY_UP:
        if (this.state.fullscreen && getState() <= MQ_SMALL) {
          this.setState({ showInfo: true });
        }
        break;
      case KEY_DOWN:
        if (this.state.fullscreen && getState() <= MQ_SMALL) {
          this.setState({ showInfo: false });
        }
        break;
      case KEY_ESC:
        this.stopFullscreen();
        break;
      default:
        return false;
    }
  };

  handleSwipe = (element, direction) => {
    if (element === this.root) {
      switch (direction) {
        case SWIPE_LEFT:
          this.handleChange(this.state.currentIndex - 1);
          break;
        case SWIPE_RIGHT:
          this.handleChange(this.state.currentIndex + 1);
          break;
        case SWIPE_TOP:
          if (
            this.state.fullscreen &&
            getState() <= MQ_SMALL &&
            !this.props.obj[this.state.currentIndex].is_recommendation
          ) {
            this.setState({ showInfo: false });
          }
          break;
        case SWIPE_BOTTOM:
          if (
            this.state.fullscreen &&
            getState() <= MQ_SMALL &&
            !this.props.obj[this.state.currentIndex].is_recommendation
          ) {
            this.setState({ showInfo: true });
          }
          break;
        default:
          return false;
      }
    }
  };

  handleChange(pos, rewriteHistory = true) {
    if (pos === -1 || pos === this.props.obj.length) {
      return false;
    }

    this.setState({ showImg: false });

    const advertisementTargets = this.getAdsPosition();

    this.itemIteration += 1;
    let eventPayload;

    // In any other case than ads on mobile, we do the normal stuff
    this.setState({ currentIndex: pos });

    const currentObj = this.props.obj[this.state.currentIndex];
    const route = this.handleRoute(pos);

    eventPayload = {
      source: 'async-diaporama',
      fullscreen: this.state.fullscreen,
      uniqueDisqusThread: true,
      photo: currentObj.img
        ? this.props.obj[this.state.currentIndex].img.src
        : this.props.obj[0].main_img_src,
      news: this.props.entityId,
      ...advertisementTargets
    };

    if (rewriteHistory) {
      this.rewriteHistory(pos, route);
    }

    /* https://jira.webedia-group.net/browse/ALLOCINE-9865 */
    window.dataLayerGA.dimension96 = this.state.fullscreen ? 1 : 0;
    /* https://jira.webedia-group.net/browse/ALLOCINE-9865 */

    this.pageHitTracking(route);

    eventEmitter.emit(EventsTypes.ASYNCHRONOUS_PAGE_CHANGE, eventPayload);
  }

  handleDoubleClick() {
    // Global browser doubleClick handler (juste for our loved CTO and is safari's browser on iPhone,
    // otherwise we could use REACT double click event)
    if (this.doubleClickTimer === null) {
      this.doubleClickTimer = setTimeout(() => {
        this.doubleClickTimer = null;
      }, 500);
    } else {
      clearTimeout(this.doubleClickTimer);
      this.doubleClickTimer = null;
      this.startFullscreen();
    }
  }

  handleSrcSizeUrl(src) {
    return {
      SIZE_DEFAULT: getImageUrl({ src: src, format: { rw: 1280, rh: 720 } }),
      SIZE_FB: getImageUrl({ src: src, format: { rw: 640, rh: 360 } }),
      SIZE_4COL: getImageUrl({ src: src, format: { rw: 1920, rh: 1080 } }),
      SIZE_3COL: getImageUrl({ src: src, format: { rw: 1280, rh: 720 } }),
      SIZE_2COL: getImageUrl({ src: src, format: { rw: 1024, rh: 576 } }),
      SIZE_1COL: getImageUrl({ src: src, format: { rw: 512, rh: 288 } })
    };
  }

  startFullscreen() {
    this.setState({ fullscreen: true });
    addClass(document.documentElement, 'open-overlay');
  }

  stopFullscreen() {
    this.setState({
      fullscreen: false,
      showInfo: false
    });
    removeClass(document.documentElement, 'open-overlay');
  }

  showInfo() {
    if (this.state.fullscreen && getState() <= MQ_SMALL) {
      this.setState(prevState => ({ showInfo: !prevState.showInfo }));
    }
  }

  pageHitTracking() {
    agofHit();
    gemiusHit();
  }

  rewriteHistory(pos, url) {
    const obj = this.props.obj[pos];
    const title = obj.title;

    if (!obj) {
      return false;
    }

    window.history.pushState(obj, title, url);
  }

  handleRoute(pos) {
    // SEO RELOU CASE :/
    // if slide doesn't have a title or text (description), SEO don't want them to be crawled.
    // So we hash the route, else classic search param for pagination unless we come back to the first page,
    // where we just should have the START_PATH
    if (!pos) {
      return START_PATH;
    }

    const currentObj = this.props.obj[pos];
    let param;

    switch (currentObj.seo_mode) {
      case PARSINGMODE_NONE:
        param = '#';
        break;
      case PARSINGMODE_TITLE:
        param = currentObj.title ? '?' : '#';
        break;
      case PARSINGMODE_DESC:
        param = currentObj.text ? '?' : '#';
        break;
      case PARSINGMODE_TITLEORDESC:
        param = currentObj.title || currentObj.text ? '?' : '#';
        break;
      case PARSINGMODE_TITLEANDDESC:
      default:
        param = currentObj.title && currentObj.text ? '?' : '#';
    }

    return `${START_PATH + param}page=${pos + 1}`;
  }

  desObfuscate(html) {
    return html.replace(/href="(.*?)"/g, (_match, obfuscatedUrl) => {
      return `href="${desobfuscateUrl(obfuscatedUrl)}"`;
    });
  }

  renderRoller() {
    const rollerItems = this.props.obj.map((item, index) => {
      const rollerItemClass = classNames('roller-item', {
        current: index === this.state.currentIndex,
        'roller-item-reco': item.is_recommendation
      });

      if (item.is_recommendation) {
        return (
          <div
            key={`${item.title}-end`}
            className={rollerItemClass}
            onClick={this.handleChange.bind(this, index)}
          >
            {item.title}
          </div>
        );
      }

      return (
        <div
          key={`${item.title}-${item.page}`}
          className={rollerItemClass}
          onClick={this.handleChange.bind(this, index)}
        >
          <img
            className="roller-thumb"
            src={item.img.src_thumb}
            width="80"
            height="80"
            alt={item.title}
          />
        </div>
      );
    });

    return (
      <Roller id="slideshow-roller" className="slideshow-roller" offset={1}>
        {rollerItems}
      </Roller>
    );
  }

  renderBtnSlideshow() {
    const currentIndex = this.state.currentIndex;

    const btnPrevClass = classNames('btn btn-prev icon icon-arrow-left', {
      hide: currentIndex === 0
    });

    const btnNextClass = classNames('btn btn-next icon icon-arrow-right', {
      hide: currentIndex === this.props.obj.length - 1
    });

    return (
      <div className="slideshow-btn">
        <button
          type="button"
          className={btnPrevClass}
          onClick={this.handleChange.bind(this, currentIndex - 1)}
        />
        <button
          type="button"
          className={btnNextClass}
          onClick={this.handleChange.bind(this, currentIndex + 1)}
        />
      </div>
    );
  }

  renderAds(adsPosition, adsClassname) {
    return (
      <div className={adsClassname} data-name={trans('advertisement')}>
        <AdPosition position={adsPosition} />
      </div>
    );
  }

  renderSocial() {
    const item = this.props.obj[this.state.currentIndex];
    const title = !item.is_recommendation
      ? item.title || item.main_title
      : this.props.obj[0].main_title;

    const link = encodeURIComponent(window.location.href);
    const className = 'button-social';
    return (
      <div className="social">
        <FacebookShareButton className={className} link={link} />
        <TwitterShareButton className={className} link={link} message={title} />
      </div>
    );
  }

  renderCounter() {
    const current = this.state.currentIndex + 1;

    if (this.props.obj.length < 2) {
      return null;
    }
    return current;
  }

  renderBtnFullscreen() {
    return (
      <button
        type="button"
        className="slideshow-fullscreen-btn icon icon-fullscreen-square"
        onClick={this.startFullscreen.bind(this)}
      />
    );
  }

  renderImgContent(img, title = '') {
    if (this.props.obj[this.state.currentIndex].is_recommendation) {
      return null;
    }

    const size = this.handleSrcSizeUrl(img.src_filename);

    const imgClass = classNames('img-tag', {
      'show-img': this.state.showImg
    });

    return (
      <picture className="slideshow-img">
        <source
          className="src"
          media="(min-width: 64em)"
          srcSet={size.SIZE_4COL}
        />
        <source
          className="src"
          media="(min-width: 45em)"
          srcSet={size.SIZE_3COL}
        />
        <source
          className="src"
          media="(min-width: 30em)"
          srcSet={size.SIZE_2COL}
        />
        <source
          className="src"
          media="(min-width: 1em)"
          srcSet={size.SIZE_1COL}
        />
        <img
          className={imgClass}
          src={size.SIZE_DEFAULT}
          width={img.width}
          height={img.height}
          alt={title}
          onClick={this.handleDoubleClick.bind(this)}
          onLoad={() => {
            this.setState({ showImg: true });
          }}
        />
      </picture>
    );
  }

  renderTitleContent(title) {
    const picto = this.state.showInfo ? '-' : '+';
    const counter = this.renderCounter();

    return (
      <div className="title">
        {title ? (
          <div
            className="title-txt"
            dangerouslySetInnerHTML={{ __html: counter + '. ' + title }}
          />
        ) : null}
        <button
          type="button"
          id="more-desc"
          className="more"
          onClick={this.showInfo.bind(this)}
        >
          {picto}
        </button>
      </div>
    );
  }

  renderDescriptionContent(text) {
    return (
      <div className="description">
        <div
          className="description-txt"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        <span
          id="more-in-page"
          className="more-in-page"
          onClick={() => {
            if (getState() <= MQ_SMALL) {
              this.setState({ showInfo: true });
            }

            this.startFullscreen();
          }}
        >
          {trans('news_slideshow.read_more')}
        </span>
      </div>
    );
  }

  renderContainerRecomendation() {
    if (
      !this.props.obj[this.state.currentIndex].is_recommendation ||
      !this.props.obj[this.state.currentIndex].items.length
    ) {
      return null;
    }

    const recoItem = this.props.obj[this.state.currentIndex].items.map(item => {
      return (
        <a
          href={desobfuscateUrl(item.href)}
          key={item.id}
          className="slideshow-card"
        >
          <img
            className="slideshow-card-img"
            src={item.img_src}
            alt={item.title}
          />
          <span className="slideshow-card-title">{item.title}</span>
        </a>
      );
    });

    return (
      <div className="slideshow-recommendation">
        <div className="titlebar">
          <div className="titlebar-title titlebar-title-sm">
            {this.props.obj[this.state.currentIndex].title}
          </div>
        </div>

        <div className="gd gd-gap-15 gd-xs-1 gd-s-2">
          {recoItem[0]}
          {recoItem[1]}
          {recoItem[2]}
          {recoItem[3]}
        </div>

        <button
          type="button"
          className="button button-md button-default-full icon icon-replay"
          onClick={this.handleChange.bind(this, 0)}
        >
          <span className="txt">
            {this.props.obj[this.state.currentIndex].btn_txt}
          </span>
        </button>
      </div>
    );
  }
  /* This container is made for ads (seed tag).
  The container is common, but seed-tag needs a sibling and not a parent element to be hosted ...
  so we have added the seed-tag-anchor div */
  renderPartnerContainer() {
    const bannerSlideshow = this.renderAds(
      AD_SLIDESHOW_NATIVE_BANNER,
      'slideshow-ads-banner'
    );
    return (
      <Fragment>
        {bannerSlideshow}
        <div className="partner-container">
          <div className="seed-tag-anchor"></div>
        </div>
      </Fragment>
    );
  }

  renderSlideshow(img, title, text, copy) {
    const currentObj = this.props.obj[this.state.currentIndex];

    const adsAtf =
      this.state.fullscreen && this.props.fullscreenAds
        ? this.renderAds(AD_SLIDESHOW_ATF, 'slideshow-ads')
        : null;

    const roller = this.renderRoller();
    const social = !currentObj.is_recommendation ? this.renderSocial() : null;
    const btnNav = this.renderBtnSlideshow();
    const btnFullscreen = !this.state.fullscreen
      ? this.renderBtnFullscreen()
      : null;
    const imgContent = img ? this.renderImgContent(img, title) : null;
    const titleContent = !currentObj.is_recommendation
      ? this.renderTitleContent(title)
      : null;
    const description =
      text && !currentObj.is_recommendation
        ? this.renderDescriptionContent(text)
        : null;
    const copyright =
      copy && !currentObj.is_recommendation ? (
        <div className="copyright light">&copy; {copy}</div>
      ) : null;
    const recoContent = currentObj.is_recommendation
      ? this.renderContainerRecomendation()
      : null;

    const partnerContainerFullScreen = this.state.fullscreen
      ? this.renderPartnerContainer()
      : null;
    const partnerContainerInpage = !this.state.fullscreen
      ? this.renderPartnerContainer()
      : null;

    const diapoClasses = classNames('slideshow', {
      'slideshow-full': this.state.fullscreen,
      'show-info': this.state.showInfo && this.state.fullscreen,
      'show-reco': currentObj.is_recommendation,
      'slideshow-full-no-ads': !this.props.fullscreenAds
    });

    return (
      <div id="slideshow" className={diapoClasses}>
        <div className="slideshow-container">
          {partnerContainerFullScreen}
          <div className="image-container">
            {imgContent}
            {recoContent}
            {btnFullscreen}
          </div>
          {roller}
          {partnerContainerInpage}
          <div className="slideshow-content">
            {titleContent}
            {description}
            {copyright}
            {social}
          </div>
          {btnNav}
        </div>
        {adsAtf}
      </div>
    );
  }

  renderSlideshowFullscreen(img, title, text, copy) {
    const slideshow = this.renderSlideshow(img, title, text, copy);

    return (
      <div className="overlay" id="overlay">
        <div className="overlay-content" id="overlay-content">
          {slideshow}
        </div>
        <div
          className="overlay-close js-trigger-overlay-close icon icon-cross"
          id="overlay-close"
          onClick={this.stopFullscreen.bind(this)}
        />
      </div>
    );
  }

  render() {
    const currentObj = this.props.obj[this.state.currentIndex];

    const img = !currentObj.is_recommendation ? currentObj.img : null;
    const copy = !currentObj.is_recommendation
      ? currentObj.img.copyright
      : null;
    let title = !currentObj.is_recommendation ? currentObj.title : null;
    let text = !currentObj.is_recommendation ? currentObj.text : null;

    title = title ? this.desObfuscate(currentObj.title) : null;
    text = text ? this.desObfuscate(currentObj.text) : null;

    if (this.state.fullscreen && !this.props.embed) {
      return this.renderSlideshowFullscreen(img, title, text, copy);
    }

    return this.renderSlideshow(img, title, text, copy);
  }
}

export default Slideshow;
