import React, { createRef, useEffect, useLayoutEffect } from 'react';

import { createPosition, removePosition } from 'common/module/advertisement';

type AdPositionProps = {
  classnames?: string;
  position: string;
};

const Index = ({ classnames, position }: AdPositionProps) => {
  const refAd = createRef<HTMLDivElement>();
  useEffect(() => {
    if (position && refAd.current) {
      createPosition(position, refAd.current);
    }
  }, [position, refAd]);

  // Called before disassembling the component to
  // get the DOM reference before it is destroyed
  useLayoutEffect(
    () => () => {
      removePosition(position, refAd.current);
    },
    []
  );

  return <div ref={refAd} className={classnames} />;
};

export default Index;
