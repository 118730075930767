import roller from 'common/module/ui/roller';

import applicationBootstrap from 'website/bootstrap';
import diaporama from 'website/containers/slideshow/slideshow';

// bootstrap the application / store
applicationBootstrap().then(() => {
  roller();
  diaporama();
});
